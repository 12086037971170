import imagesLoaded from 'imagesloaded';
import barba from '@barba/core';
import barbaPrefetch from '@barba/prefetch';

import gsap from '../lib/gsap.min';
import ScrollTrigger from '../lib/ScrollTrigger.min';
import ScrollToPlugin from '../lib/ScrollToPlugin.min';
gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';

export function main() {
	
var Module = {};
var body;

Module.Compornent = {
	mobileToggleAsset: false,
	checkClass: function($this){
		$this = null;
	}
};

(function($) {
Module.Events = (function (){
'use strict';

var OBSERVER = {
	handlers: [],
	observeEvents: function(target){
		this.handlers.push(target);
	},
	deleteListener: function(element,listener){
		element.off(listener);
		element = null;
		listener = null;
	},
	clearEvents: function(){
		$(window).off('scroll resize');
		cancelAnimationFrame(timeReq);
	}
};

var winH = $(window).height();
var winW = $(window).width();
var body = $('body');


function nav_show(){
	body.addClass('navopen');
	body.removeClass('navclose');
	const _tar = document.getElementById('main_nav');
	const _el = _tar.querySelectorAll('span');
	gsap.fromTo(_el,{y:60},{
		y:0,
		duration: 0.6,
		ease: "power4.out",
		delay: 0.4,
		stagger: 0.05,
	});
}
function nav_hide(){
	body.removeClass('navopen');
	body.addClass('navclose');
}
function navInit(){
	body.addClass('navclose');
	$('#nav_btn').on('click', function(){
		if( body.hasClass('navopen') ){
			nav_hide();
		}else{
			nav_show();
		}
	});
	// window.addEventListener('scroll', function(){
	// 	nav_hide();
	// });
}

function navChange(){
	nav_hide();
	const namespace = $('[data-barba="container"]').attr('data-barba-namespace');
	$('#nav').attr('data-namespace', namespace);
}


function elFunc(){
	let mm = gsap.matchMedia();
	document.querySelectorAll('.el').forEach((_tar, i) => {
		let _elmc = _tar.querySelectorAll('.elc');
		if( _elmc.length == 0 ){
			_elmc = _tar;
		}
		gsap.set(_elmc,{ opacity:0, y:60 });
		gsap.to(_elmc,
			{ opacity:1, y:0, 
				duration: 1.2,
				ease: "power4.out",
 				stagger: 0.1,
				scrollTrigger: {
					trigger: _tar,
					start: "top bottom-=10%",
					onRefresh: self => self.progress === 1 && self.animation.progress(1)
				}
			}
		);
	});

	const up_scrollTrigger = ScrollTrigger.create({
		trigger: body,
		start: "top top",
		onUpdate: self => {
			const _slfscrl = self.scroll();
			if(self.direction == -1){
				document.body.classList.remove('scrldown');
			}else{
				if(_slfscrl > 10){
					if(self.prevSclrl < _slfscrl-1){
						document.body.classList.add('scrldown');
					}
					self.prevSclrl = _slfscrl;
				}
			}
			scrltop (_slfscrl);
		},
	});
	body.addClass('scrltop');
}
const scrltop = (_slfscrl) => {
	if(_slfscrl > 10){
		body.removeClass('scrltop');
		body.addClass('scrl');
	}else{
		body.removeClass('scrl');
		body.addClass('scrltop');
	}
}


async function ScrollSmootherKill() {
	await new Promise((resolve, reject) => {
		ScrollTrigger.killAll();
		resolve();
	})
}


var tar_offset=0;
if(window.matchMedia('(max-width:767px)').matches){
	tar_offset = 58;
}
function pageScroll(){
	if (location.hash) {
    var target = location.hash;
		gsap.to(window, { duration:0.6, delay:0.1, scrollTo:{y:target, autoKill:false, offsetY:tar_offset}, ease:'power2'});	
  }
	$('a.anchor').on('click', function(e){
		nav_hide();
		var hrefPageUrl = $(this).prop("href");
		hrefPageUrl = hrefPageUrl.split("#")[0];
		var currentUrl = location.href;
		currentUrl = currentUrl.split("#")[0];
		if( hrefPageUrl == currentUrl ){
			e.preventDefault();
			let href = this.hash;
			gsap.to(window, { duration:0.6, scrollTo:{y:href, autoKill:false, offsetY:tar_offset}, ease:'power2'});	
		}
	});
}


const kvtxt_color = (e) => {
	let txt_color;
	if( e ){
		const slide = e.slides[e.activeIndex];
		txt_color = slide.getAttribute('data-txtcolor');
	}else{
		const pagekv = document.getElementById('pagekv');
		txt_color = pagekv.getAttribute('data-txtcolor');
	}
	body.removeClass('kvtxt_wh kvtxt_bk');
	body.addClass('kvtxt_'+txt_color);
}


const topkv = async () => {
	gsap.set('#topkvSwiper', {opacity:0});
	await new Promise((resolve, reject) => {
		const swiper = new Swiper('#topkvSwiper', {
			effect: 'fade',
			fadeEffect: {
				crossFade: true
			},
			speed: 800,
			// loop: true,
			autoplay: {
				delay: 4000,
			},
			pagination: {
				el: '.swiper-pagination',
				clickable:true,
			},
			on: {
				init: function (e) {
					e.autoplay.pause();
					kvtxt_color(e);
					ScrollTrigger.refresh();
					gsap.to('#topkvSwiper', {opacity:1, duration:0.3, delay:0.8,
					onComplete:function(){
						e.autoplay.start();
					}});
				},
				realIndexChange: function(e){
					kvtxt_color(e);
				}
			}
		});
		resolve();
	});
}


const talentSlider = async () => {
	await new Promise((resolve, reject) => {
		const talentSwiper = document.getElementById('talentSwiper');
		const checkSlidesCount = (swiper) =>{
			const slidesCount = swiper.slides.length;
			const slidesPerView = swiper.params.slidesPerView;
			if (slidesCount < slidesPerView) {
				talentSwiper.classList.add('less');
			}else{
				talentSwiper.classList.remove('less');
			}
		}

		const col4Last = (swiper) => {
			const _index = swiper.activeIndex;
			swiper.slides.forEach(elm => {
				elm.classList.remove('last');
			});
			//if swiper.slides[_index+3] is defined, it will add class
			if(swiper.slides[_index+3]){
				swiper.slides[_index+3].classList.add('last');
			}			
		}

		const swiper = new Swiper(talentSwiper, {
			speed: 600,
			slidesPerView: 2,
			spaceBetween: 0,
			// loop: true,
			keyboard: {
				enabled: true,
				onlyInViewport: false,
			},
			navigation: {
				nextEl: '.snext',
				prevEl: '.sprev',
			},
			breakpoints: {
				767: {
					slidesPerView: 4,
				}
			},
			on: {
				init: function () {
					checkSlidesCount(this);
					col4Last(this);
					ScrollTrigger.refresh();
				},
				resize: function () {
					checkSlidesCount(this);
				},
				slideChange: function () {
					col4Last(this);
				},
			}
		});
		resolve();
	});
}


function kvParallax(){
	gsap.utils.toArray('.kvpara').forEach(tar => {
		gsap.fromTo(tar, {y: 0},
			{
				yPercent: 50,
				scrollTrigger: {
					trigger: tar,
					start: 'top top',
					end: 'bottom top',
					scrub: true,
				},
			}
		);
	});
}


function expandFunc() {
	const expand = (parent, state) => {
		if(state){
			parent.classList.add('open');
		}else{
			parent.classList.remove('open');
		}
		const exc = parent.querySelector('.exc');
		gsap.utils.toArray(exc).forEach(ex => {
			gsap.to(ex,{
				height: (!state) ? 0 : "auto",
				duration:0.3,
				ease: "power2.inOut",
				onComplete: () => {
					ScrollTrigger.refresh();
				}
			});
		});
	}
	const expand_event = (e) => {
		let parent = e.target.closest('.ex');
		const result = parent.classList.contains('open');
		if (result) {
			expand(parent, false);
		}else{
			expand(parent, true);
		}
	};
	gsap.utils.toArray('.exbtn').forEach(exbtn => {
		let parent = exbtn.closest('.ex');
		expand(parent, false);
		exbtn.removeEventListener('click',expand_event);
		exbtn.addEventListener('click',expand_event);
	});
}


var PageEvents = {
	loadFunc: function(){
		navInit();
		elFunc();
	},
	commonFunc: function(){
		this.pjaxReadyFunc();
		winH = $(window).height();
		winW = $(window).width();	
		pageScroll();
		// navChange();
		expandFunc();
		OBSERVER.observeEvents(1);
	},
	pjaxReadyFunc: function(){
	},
	topFunc: async function(){
		body.addClass('top');
		body.addClass('fullkv');
		await topkv();
		await talentSlider();
		kvParallax();
	},
	worksingleFunc: function(){
		body.addClass('fullkv');
		kvParallax();
		kvtxt_color();
	}
};

const replaceBogo = function(data){
	let target = '#wrapper';
	if(data){
		target = data.next.html;
	}
	const $newPageBogo = $(target).find('.bogo-language-switcher').html();
	body.find('#bogo').html($newPageBogo);
	$('#bogo').find('a').addClass('nopj');
}

function pjaxSettings(){

	barba.use(barbaPrefetch);

	replaceBogo();

	const replaceHead = function(data){
		const head = document.head;
		const newPageRawHead = data.next.html.match(/<head[^>]*>([\s\S.]*)<\/head>/i)[0];
		const newPageHead = document.createElement('head');
		newPageHead.innerHTML = newPageRawHead;
		const removeHeadTags = [ 
			"meta[name='keywords']"
			,"meta[name='description']"
			,"meta[property^='og']"
			,"meta[name^='twitter']"
			,"meta[itemprop]"
			,"link[itemprop]"
			,"link[rel='prev']"
			,"link[rel='next']"
			,"link[rel='canonical']"
		].join(',');
		const headTags = head.querySelectorAll(removeHeadTags)
		for(let i = 0; i < headTags.length; i++ ){
			head.removeChild(headTags[i]);
		}
		const newHeadTags = newPageHead.querySelectorAll(removeHeadTags)
		for(let i = 0; i < newHeadTags.length; i++ ){
			head.appendChild(newHeadTags[i]);
		}
	}

	var eventDelete = function eventDelete(e) {
		if (e.currentTarget.href === window.location.href) {
			e.preventDefault()
			e.stopPropagation()
			return
		}
	}
	const links = Array.prototype.slice.call(document.querySelectorAll('a[href]'));
		links.forEach(function (link) {
			link.addEventListener('click', function (e) {
			eventDelete(e)
		}, false)
	})

	var gaPush = function gaPush(pagename) {
		if (typeof ga === 'function' && Barba.HistoryManager.history.length >= 1) {
			ga('send', 'pageview', pagename);
		}
		if (typeof gtag === 'function' && Barba.HistoryManager.history.length >= 1) { 
			// gtag('config', 'G-Xxxxxxxx', {'page_path': pagename}); 
		}
	}

	var preventSettings = function preventSettings(el) {
		let href = el.getAttribute('href');
		if(href == null){
			href = el.getAttribute('xlink:href');
		}
		if(el.classList.contains('nopj')){
			return true;
		}
		let site_url = location.protocol + '//' + location.host;
		if (!href.startsWith(site_url)) {
			el.setAttribute('target','_blank');
			return true;
		}
		let url = location.protocol + '//' + location.host + location.pathname;
		let extract_hash = href.replace(/#.*$/,"");
		if (href.startsWith(location.protocol + '//' + location.host)) {
			if (href.indexOf('#') > -1 && extract_hash != url ){
				return false;
			}
		}
		if (/\.(xlsx?|docx?|pptx?|pdf|jpe?g|png|gif|svg)/.test(href.toLowerCase())) {
			el.setAttribute('target','_blank');
			return true;
		}
	}

	async function scroll(data) {
		await new Promise((resolve, reject) => {
			let scrltarget = 0;
			if(data.trigger === "back") {
				scrltarget = scrollPosY;
			}
			scrollPosY = barba.history.current.scroll.y;
			if(data.trigger === "forward"){
				scrltarget = scrollForwardPosY;
			}

			if(location.hash){
				if(window.matchMedia('(max-width:767px)').matches){
					tar_offset = 58;
				}else{
					tar_offset = 0;
				}
				var anchor = document.querySelector( location.hash );
				if(anchor){
					var target = location.hash;
					gsap.to(window, { duration:0, scrollTo:{y:target, autoKill:false, offsetY:tar_offset}, ease:'power2', onComplete:function(){
						body.addClass('scrldown');
						resolve();
					}});	
				}else{
					gsap.to(window, { duration:0, scrollTo:{y:0, autoKill:false}, ease:'power2', onComplete:function(){
						resolve();
					}});
				}
			}else{
				gsap.to(window, { duration:0, scrollTo:{y:scrltarget, autoKill:false}, ease:'power2', onComplete:function(){
					body.removeClass('scrldown');
					resolve();
				}});
			}
		})
	}


	barba.init({
		prevent: function prevent(_ref) {
			var el = _ref.el;
			return preventSettings(el);
		},
		timeout: 10000,			
		transitions: [{
			name: 'transition',
			sync:true,
			leave:function(data) {
				const done = this.async();
				gsap.to('#headerlogo', {opacity:0, duration:0.2})
				gsap.to('#footer', {opacity:0, duration:0.2})
				gsap.to('footer.el', {opacity:0, duration:0.2})
				gsap.to(data.current.container, {opacity:0, duration:0.2, onComplete:function(){
					ScrollSmootherKill();
					done();
				}});
			},
			enter:function(data) {
				const done = this.async();
				const $elm = $(data.next.container);
				$elm.css({'opacity':0});
				done();
			}
		}]
	})

	barba.hooks.beforeLeave(() => {
		body.removeClass('ready top inpage navopen scrldown scrltop scrl onevh fullkv kvtxt_wh kvtxt_wh');
	});

	barba.hooks.beforeEnter((data) => {
		replaceHead(data);
		replaceBogo(data);
		if( OBSERVER.handlers.length ){
			OBSERVER.clearEvents();
		}
		gaPush(location.pathname);
	});

	if (history.scrollRestoration) {
		history.scrollRestoration = 'manual';
	}
	var scrollPosY = 0;
	var scrollForwardPosY = 0;
	barba.hooks.leave((data) => {
		scrollForwardPosY = data.current.container.scroll.y;
	});

	barba.hooks.after((data) => {
		async function callerFun(){
			await pageCheck();
			await scroll(data);
			gsap.to('#headerlogo', {opacity:1, duration:0.6})
			gsap.to('footer', {opacity:1, duration:0.6})
			gsap.to(data.next.container, {opacity:1, duration:0.6, onStart:function(){
				elFunc();
			}});
		}
		$('#container').imagesLoaded( function() {
			callerFun();
		});
	});
}

async function pageCheck() {
	await new Promise((resolve, reject) => {
		PageEvents.commonFunc();

		if( document.getElementById('top') ){
			PageEvents.topFunc();
		}else{
			body.addClass('inpage');
		}
		if( document.getElementById('worksingle') ){
			PageEvents.worksingleFunc();
		}
		body.addClass('ready');
		resolve();
	})
}

function initialize(){
	window.addEventListener('DOMContentLoaded', function(){
		PageEvents.loadFunc();
		pageCheck();
		pjaxSettings();
		body.addClass('siteloaded');
	});
	window.addEventListener('load', function(){
		body.addClass('siteloaded');
	});
}

initialize();

})();
})( jQuery );

}